import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

const list = async projectId => {
  const res = await graphqlClient.query({
    query: gql`
      {
        financing_type(where: {project_id:{_eq:"${projectId}"}}) {
          id
          name
          interest_rate
          max_years
          monthly_insurance_rate
          active
          bank {
            id
            name
          }
        }
      }
    `
  });

  return res.data.financing_type;
};

const add = async (name, interestRate, maxYears, projectId, bankId) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation($ftype: financing_type_insert_input!) {
        insert_financing_type_one(object: $ftype) {
          id
        }
      }
    `,
    variables: {
      ftype: {
        name: name,
        interest_rate: interestRate,
        max_years: maxYears,
        monthly_insurance_rate: 0,
        project_id: projectId,
        bank_id: bankId
      }
    }
  });

  return res.data.insert_financing_type_one;
};

const remove = async financingTypeId => {
  const res = await graphqlClient.mutate({
    mutation: gql`mutation {
      delete_financing_type_by_pk(id: "${financingTypeId}") {
        id
      }
    }`
  });

  return res.data.delete_financing_type_by_pk;
};


const upd = async (id, financingType) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation upd($id: uuid!, $set: financing_type_set_input!){
        update_financing_type_by_pk(pk_columns:{
          id: $id
        }, _set: $set) {
          id
        }
      }
    `,
    variables: {
      id,
      set: {
        active: financingType.active,
        bank_id: financingType.bankId,
        interest_rate: financingType.interestRate,
        max_years: financingType.maxYears
      }
    }
  });

  return res.data.update_financing_type_by_pk;
};


export default {
  list,
  add,
  remove,
  upd
};
