<template>
  <div>
    <div class="justify-center flex mt-4">
      <ul class="divide-y divide-gray-200">
        <li class="py-4 flex">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check-circle"
            :class="checkFirstPay"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            ></path>
          </svg>
          <div class="ml-3">
            <p class="text-xl font-normal text-gray-900">
              Valores de cuota inicial
            </p>
            <a
              v-if="!inicialPercentValuesReady"
              @click="modalMinDownPayment = true"
              href="#"
              class="font-medium"
              >Configurar ahora</a
            >
            <a v-else class="font-medium text-success-flattlo">Listo</a>
          </div>
        </li>

        <li class="py-4 flex">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check-circle"
            :class="checkFinancing"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            ></path>
          </svg>
          <div class="ml-3">
            <p class="text-xl font-normal text-gray-900">
              Entidad de financiación
            </p>
            <a
              v-if="!financingValuesReady"
              href="#"
              @click="modalFinancing = true"
              class="font-medium"
              >Configurar ahora</a
            >
            <a v-else class="font-medium text-success-flattlo">Listo</a>
          </div>
        </li>
      </ul>
    </div>
    <!-- PopUp percent separation -->
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-show="modalMinDownPayment"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        >
          <button
            @click="modalMinDownPayment = false"
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div>
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-blue-flattlo"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="percent"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 0 1-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                ></path>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                Ingresa los siguientes datos:
              </h3>
              <div class="w-full mt-2 p-4">
                <p class="text-left text-md mb-1">
                  Porcentaje de cuota inicial:
                </p>
                <input
                  type="number"
                  v-model="inicialPercent"
                  name="porcentaje"
                  id="percentSeparation"
                  class="inputBorder focus:ring-blue-flattlo focus:border-blue-flattlo py-4 block w-full text-xl border-blue-flattlo rounded-md"
                  placeholder="%"
                />
              </div>
              <div class="w-full p-4 -mt-2">
                <p class="text-left text-md mb-1">Meses de fraccionamiento:</p>
                <input
                  type="number"
                  v-model="fractionMonth"
                  name="porcentaje"
                  id="percentSeparation"
                  class="inputBorder focus:ring-blue-flattlo focus:border-blue-flattlo py-4 block w-full text-xl border-blue-flattlo rounded-md"
                />
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <div
              v-if="inicialPercent != 0 && fractionMonth != 0"
              @click="
                editProject(
                  'min_downpayment_percent',
                  inicialPercent,
                  'de valor para cuota inicial'
                ),
                  editProject(
                    'max_downpayment_installments',
                    fractionMonth,
                    'de fraccionamiento'
                  )
              "
              type="button"
              class="inline-flex justify-center p-2 w-full rounded-md border border-transparent shadow-sm px-6 py-4 text-xl font-medium text-white bg-blue-flattlo hover:bg-success-flattlo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled
            >
              Guardar valores
            </div>
            <button
              v-else
              type="button"
              class="inline-flex justify-center p-2 w-full rounded-md border border-transparent shadow-sm px-6 py-4 text-xl font-medium text-white bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled
            >
              Guardar valores
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End PopUp percent separation -->

    <!-- PopUp percent separation -->
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-show="modalFinancing"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        >
          <button
            @click="modalFinancing = false"
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              @click="selectedEntities = []"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div>
            <div
              class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-100"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="piggy-bank"
                class="h-9 w-9 text-blue-flattlo"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M560 224h-29.5c-8.8-20-21.6-37.7-37.4-52.5L512 96h-32c-29.4 0-55.4 13.5-73 34.3-7.6-1.1-15.1-2.3-23-2.3H256c-77.4 0-141.9 55-156.8 128H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h40c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-48h128v48c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-80.7c11.8-8.9 22.3-19.4 31.3-31.3H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-128 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM256 96h128c5.4 0 10.7.4 15.9.8 0-.3.1-.5.1-.8 0-53-43-96-96-96s-96 43-96 96c0 2.1.5 4.1.6 6.2 15.2-3.9 31-6.2 47.4-6.2z"
                ></path>
              </svg>
            </div>
            <div>
              <p class="text-left mt-6">Bancos disponibles:</p>
              <select
                v-model="selectedEntities"
                id="percentSeparation"
                name="location"
                class="mt-1 block w-full pl-3 pr-10 py-4 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 rounded-md"
              >
                <option
                  v-for="o in bankList"
                  :key="o.id"
                  :value="{ id: o.id, name: o.name }"
                >
                  {{ o.name }}
                </option>
              </select>
              <div class="flex gap-2">
                <div class="mt-3">
                  <p class="text-left">Tasa de interes:</p>
                  <input
                    v-show="selectedEntities.length > 0"
                    type="number"
                    name="porcentaje"
                    id="percentSeparation"
                    v-model="entityInterestRate"
                    class="inputBorder focus:ring-blue-flattlo focus:border-blue-flattlo py-4 block w-full text-xl border-blue-flattlo rounded-md"
                  />
                </div>
                <div class="mt-3">
                  <p class="text-left">Años de financiación</p>
                  <input
                    v-show="selectedEntities.length > 0"
                    v-model="yearsMax"
                    type="number"
                    name="porcentaje"
                    id="percentSeparation"
                    class="inputBorder focus:ring-blue-flattlo focus:border-blue-flattlo py-4 block w-full text-xl border-blue-flattlo rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <div
              v-if="
                selectedEntities.id != null ||
                  (undefined && entityInterestRate != 0 && yearsMax != 0)
              "
              @click="addFinancingProject()"
              type="button"
              class="inline-flex justify-center p-2 w-full rounded-md border border-transparent shadow-sm px-6 py-4 text-xl font-medium text-white bg-blue-flattlo hover:bg-success-flattlo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Guardar entidad
            </div>
            <div
              v-else
              type="button"
              class="inline-flex justify-center p-2 w-full rounded-md border border-transparent shadow-sm px-6 py-4 text-xl font-medium text-white bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Guardar entidad
            </div>
          </div>
          <div class="mt-3">
            <p class="text-center">
              Si deseas agregar más entidades de financiación puedes agregarlas
              en las
              <router-link to="/proyectos" target="_blank"
                >configuraciones de tu proyecto
              </router-link>
              más adelante.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End PopUp percent separation -->
  </div>
</template>

<script>
import bankApi from "../../../services/bank";
import apiFinancing from "../../properties/services/property.api";
import api from "../../projects/services/project.api";
import financingApi from "../../projects/services/financing.api";
export default {
  data() {
    return {
      modalMinDownPayment: false,
      modalFinancing: false,
      bankList: [],
      selectedEntities: [],
      inicialPercent: 0,
      inicialPercentValuesReady: false,
      financingValuesReady: false,
      fractionMonth: 0,
      dataProject: [],
      entityInterestRate: 0,
      yearsMax: 0
    };
  },
  methods: {
    async editProject(field, value, text) {
      try {
        let fields = {};
        fields[field] = value;
        await api.updProject(
          this.$store.state.project.currentProjectId,
          fields
        );
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `Fue editado correctamente.`,
          color: "success"
        });
        this.checkValueInicialQuote();
        this.modalMinDownPayment = false;
      } catch (e) {
        this.$vs.notify({
          title: `📝 El campo ${text}.`,
          text: `No ha podido ser editado debido a un error.`,
          color: "danger"
        });
      }
    },
    async getListBanks(countryId) {
      this.bankList = await bankApi.listByCountry(countryId);
    },
    async checkValueInicialQuote() {
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      const res = await apiFinancing.checkFinancingValues(propertyId);
      this.dataProject = [];
      this.dataProject = res.data.property[0].project;
      this.setValidation();
    },
    async addFinancingProject() {
      try {
        await financingApi.add(
          this.selectedEntities.name,
          this.entityInterestRate,
          this.yearsMax,
          this.$store.state.project.currentProjectId,
          this.selectedEntities.id
        );
        this.$vs.notify({
          title: `Financiamiento agregado`,
          text: `Se ha guardado el financiamiento al proyecto.`,
          color: "success"
        });
        this.selectedEntities = [];
        this.yearsMax = 0;
        this.entityInterestRate = 0;
        this.modalFinancing = false;
        this.checkValueInicialQuote();
        this.$router.push({
          path: "/seleccion-de-nivel/"
        });
        setTimeout(() => {
          this.$router.push({
            path: "/generar-cotizacion/"
          });
        }, 1000);
      } catch (e) {
        this.$vs.notify({
          title: `Error al agregar financiamiento`,
          text: `No se ha podido agregar el financiamiento al proyecto.`,
          color: "danger"
        });
      }
    },
    setValidation() {
      if (this.dataProject.min_downpayment_percent == undefined || null) {
        this.inicialPercentValuesReady = false;
      } else {
        this.inicialPercentValuesReady = true;
      }
      if (this.dataProject.financing_types.length > 0) {
        this.financingValuesReady = true;
      } else {
        this.financingValuesReady = false;
      }
    }
  },
  computed: {
    checkFirstPay() {
      if (this.dataProject.min_downpayment_percent == undefined || null) {
        return "text-gray-400 mt-2 h-8 w-8";
      } else {
        return "text-success-flattlo mt-2 h-8 w-8";
      }
    },
    checkFinancing() {
      if (this.dataProject.financing_types.length > 0) {
        return "text-success-flattlo mt-2 h-8 w-8";
      } else {
        return "text-gray-400 mt-2 h-8 w-8";
      }
    }
  },
  beforeMount() {
    this.checkValueInicialQuote();
    this.getListBanks(this.$store.getters["auth/getUser"].countryId);
  }
};
</script>

<style lang="scss">
#percentSeparation {
  border: solid 2px #9b9999 !important;
  padding: 4px !important;
}
</style>
