<template>
  <div class="scroll-wrapper">
    <div v-if="valuesReadyForQuote">
      <iframe
        v-html="code"
        class="theIframe h-screen w-full"
        :src="this.quoterUrl"
      ></iframe>
    </div>
    <div v-else>
      <div
        class="w-full specialIframeHeight rounded-lg bg-white shadow-sm vs-con-loading__container"
        id="area-with-loading"
      >
        <div>
          <div class="flex justify-center mt-3">
            <img
              :src="actualProperty.project.project_images[0].img_url"
              class="h-64 w-auto"
            />
          </div>
        </div>

        <div class="flex justify-center">
          <p class="mt-1 text-xl w-2/4 text-center">
            🔒 Módulo bloqueado, para utilizar el módulo de cotizaciones de
            {{ actualProperty.project.name }}, completa los siguientes valores:
          </p>
        </div>
        <configValidator />
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../properties/services/property.api";
import configValidator from "../components/ValidatorComponent";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      quoterUrl: null,
      valuesReadyForQuote: false,
      actualProperty: [],
      code: null,
      showQuoter: false,
    };
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#area-with-loading",
        scale: 2,
      });
    },

    ///TODO: add values for PDF Quote
    async checkQuoterValues(propertyId) {
      const res = await api.checkFinancingValues(propertyId);
      this.actualProperty = res.data.property[0];
      if (res.data.property[0].project.financing_types.length > 0) {
        this.valuesReadyForQuote = true;
      } else {
        this.valuesReadyForQuote = false;
      }

      setTimeout(() => {
        this.$vs.loading.close("#area-with-loading > .con-vs-loading");
      }, 1000);
    },
  },
  mounted() {
    this.openLoadingContained();
  },
  async beforeMount() {
    try {
      this.$store.state.project.breadcrumbs = [
        { pageName: 'Listado de propiedades', route: '/seleccion-de-nivel/' },
        {
          pageName: 'Generador de cotización',
          route: '/generar-cotizacion',
        },
      ];

      this.quoterUrl = null;
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      await this.checkQuoterValues(propertyId);

      const quoteUrl =
        process.env.VUE_APP_QUOTER_URL || "https://quoter.flattlo.com";
      const tokenSeller = this.$store.state.auth.jwt;

      /* Anterior cotizador */
      // this.quoterUrl = `${quoteUrl}/${propertyId}/${tokenSeller}`;
      /* Nuevo cotizador */
      const projectId = this.$store.state.project.currentProjectId;
      this.quoterUrl = `${quoteUrl}?tkn=${tokenSeller}&prop=${propertyId}&prj=${projectId}`;
    } catch (e) {
      sentryCaptureException(e, "quoter");
    }
  },
  destroyed() {
    this.quoterUrl = null;
  },
  components: {
    configValidator,
  },
};
</script>

<style lang="scss">
.theIframe {
  width: 100%;
  height: 1000px;
}
.specialIframeHeight {
  height: 74vh;
}
</style>